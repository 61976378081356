<template>
  <div>
    <!-- There is content in the list -->
    <template v-for="(content, index) in item.Value">
      <div :key="index" v-if="content != ''">
        <h3>
          <v-icon>mdi-file-document</v-icon>
          {{ content }}
          <v-btn
            depressed
            color="primaryred"
            icon
            @click="$delete(item.Value, index)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </h3>
      </div>
    </template>
    <v-layout align-center>
      <v-autocomplete
        v-if="item.MultipleItems"
        height="28"
        dense
        v-model="item.LinkedContent"
        :items="searchResults"
        :placeholder="item.LoadItemsWhenCreated ? '' : $t('Search') + '...'"
        small-chips
        :loading="searchingContent"
        :search-input.sync="searchContentWatch"
        :multiple="item.MultipleItems"
        :chips="item.MultipleItems"
        :deletable-chips="item.MultipleItems"
        :item-text="showItem"
        item-value="_id"
        :rules="dropdownRule"
        :clearable="!item.Required"
        hide-no-data
        :disabled="
          $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)
        "
        outlined
        hide-details="auto"
      >
        <!-- @change="searchContentWatch = ''" -->
        <template v-slot:selection="{ attr, on, item, selected }">
          <v-chip
            v-bind="attr"
            :input-value="selected"
            v-on="on"
            small
            :close="
              item.ReadOnly ||
              editItemAdmin ||
              ($store.getters.hasUserRoleForPageItem({
                role: 2,
              }) &&
                !userIsAdministrator)
            "
            @click:close="remove(item)"
          >
            <span
              v-if="item.DisplayLabels"
              v-text="item.DisplayLabels[currentLanguage]"
            ></span>
            <span v-else v-text="item.DisplayLabel"></span>
            <v-btn
              v-if="checkGoToLinkedContent"
              depressed
              class="ml-1"
              right
              x-small
              icon
              @click="
                $helpers.goToLinkedContentPage(
                  item,
                  item.PageId.$oid,
                  item._id.$oid
                )
              "
            >
              <v-icon> mdi-arrow-right </v-icon>
            </v-btn>
          </v-chip>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-else
        ref="autocomplete"
        v-model="item.LinkedContent"
        :items="searchResultsWithUpdatedItemText"
        height="28"
        :placeholder="item.LoadItemsWhenCreated ? '' : $t('Search') + '...'"
        :loading="searchingContent"
        :search-input.sync="searchContentWatch"
        :item-text="showItemAutocomplete"
        item-value="_id"
        :rules="dropdownRule"
        :clearable="!item.Required"
        hide-no-data
        :disabled="
          $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)
        "
        @keydown.enter.prevent=""
        hide-details="auto"
        dense
        outlined
      >
        <!--  @change="searchContentWatch = ''" -->
        <template v-slot:item="{ item }">
          {{
            item.DisplayLabels
              ? item.DisplayLabels[currentLanguage]
              : item.DisplayLabel
          }}
        </template>
      </v-autocomplete>
      <v-btn
        v-if="
          (userIsAdministrator && item.LinkedContent && !item.MultipleItems) ||
          ($helpers.userHasAccessToLinkedContent(item, selectedWebsite.Pages) &&
            item.LinkedContent &&
            !item.MultipleItems)
        "
        depressed
        :disabled="!item.LinkedContent"
        color="primary"
        class="ml-2"
        icon
        @click="$helpers.goToLinkedContentPage(item)"
      >
        <v-icon> mdi-arrow-right</v-icon>
      </v-btn>
    </v-layout>

    <template v-if="$store.getters.editMode">
      <hr class="mt-4" />
      <v-row no-gutters class="my-5">
        <v-col cols="12" sm="6">
          <v-select
            :items="getSharedPagesNotAssigned()"
            single-line
            v-model="item.LinkedPage"
            item-value="_id"
            :label="$t('Select a page')"
            class="mr-4 altSelect"
            outlined
            dense
          >
            <template slot="selection" slot-scope="data">
              <div class="input-group__selections__comma">
                {{ data.item.Name[currentLanguage] }}
              </div>
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.Name[currentLanguage] }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col
              cols="12"
              :lg="
                item.colWidth < 7
                  ? item.colWidth < 5
                    ? 12
                    : 4
                  : item.colWidth < 10
                  ? 4
                  : 2
              "
            >
              <v-layout column>
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  :label="$t('Column width')"
                  outlined
                  dense
                ></v-text-field>
                <v-switch
                  flat
                  :ripple="false"
                  v-model="item.LoadItemsWhenCreated"
                  :label="$t('Load items when created')"
                  hide-details="auto"
                  class="float-left mr-3"
                ></v-switch>
                <v-switch
                  flat
                  :ripple="false"
                  v-model="item.MultipleItems"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Multiple items')"
                ></v-switch>
                <v-switch
                  flat
                  :ripple="false"
                  v-model="item.Required"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Field is required')"
                ></v-switch>
                <v-switch
                  flat
                  :ripple="false"
                  v-model="item.ReadOnly"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Read-only')"
                ></v-switch>
                <v-switch
                  flat
                  :ripple="false"
                  v-model="item.RemoveCard"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Remove frame')"
                ></v-switch>
              </v-layout>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    },
    pageId: {
      type: String,
    },
  },
  data() {
    return {
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
      searchingContent: false,
      searchContentWatch: null,
      searchResults: [],
      userIsAdministrator: this.$auth.userIsAdministrator(),
      autocompleteMulti: this.item.LinkedContent,
      autocomplete: this.item.LinkedContent,
    };
  },
  created() {
    if (
      this.item.DisplayLabels != null &&
      this.item.DisplayLabels != undefined
    ) {
      this.item["ValueSorts"] = this.item.DisplayLabels;
      this.item.DisplayLabels = null;
    }

    if (this.item.DisplayLabel != null && this.item.DisplayLabel != undefined) {
      this.item["ValueSort"] = this.item.DisplayLabel;
      this.item.DisplayLabel = null;
    }

    if (this.item.LoadItemsWhenCreated) {
      this.searchContent("");
    } else if (this.item.LinkedContent) {
      this.getContent(this.item.LinkedContent);
    }
    if (!Array.isArray(this.item.LinkedContent)&&this.item.MultipleItems&&this.$route.params.itemId) {
      this.item.LinkedContent = [this.item.LinkedContent];
    }
  },
  watch: {
    searchContentWatch: function (searchValue) {
      if (!this.item.LoadItemsWhenCreated && searchValue != "") {
        if (
          !this.searchResults.find(
            (el) =>
              el.DisplayLabel == searchValue || el.DisplayLabels == searchValue
          )
        ) {
          this.searchContent(searchValue);
        }
      }
    },
    "item.LinkedContent": {
      deep: true,
      handler(val) {
        if (Array.isArray(val)) {
          var valueSort = "";
          for (var valIndex in val) {
            for (var searchResultIndex in this.searchResults) {
              if (
                val[valIndex].$oid ==
                this.searchResults[searchResultIndex]._id.$oid
              ) {
                valueSort +=
                  this.searchResults[searchResultIndex].DisplayLabel + ", ";
              }
            }
          }
          this.item.ValueSort = valueSort.substring(0, valueSort.length - 2);
          let valueSorts = {};
          for (var valIndexs in val) {
            for (var searchResultIndexs in this.searchResults) {
              if (
                val[valIndexs].$oid ==
                  this.searchResults[searchResultIndexs]._id.$oid &&
                this.searchResults[searchResultIndexs].DisplayLabels
              ) {
                for (const key in this.searchResults[searchResultIndexs]
                  .DisplayLabels) {
                  if (
                    this.searchResults[searchResultIndexs].DisplayLabels[key]
                  ) {
                    if (!valueSorts[key]) {
                      valueSorts[key] = "";
                    }
                    let item =
                      this.searchResults[searchResultIndexs].DisplayLabels[key];

                    if (item.substr(0, 1) == " ") {
                      item = item.substr(1, item.length);
                    }
                    if (item.substr(item.length - 1, 1) == " ") {
                      item = item.substr(0, item.length - 1);
                    }

                    valueSorts[key] += item + ", ";
                  }
                }
              }
            }
          }
          for (var value in valueSorts) {
            valueSorts[value] = valueSorts[value].substring(
              0,
              valueSorts[value].length - 2
            );
          }
          this.item.ValueSorts = valueSorts;
        } else if (this.searchResults.find((o) => o._id == val)) {
          if (this.searchResults.find((o) => o._id == val).DisplayLabels) {
            this.item.ValueSorts = this.searchResults.find(
              (o) => o._id == val
            ).DisplayLabels;
          }
          if (this.searchResults.find((o) => o._id == val).DisplayLabel) {
            this.item.ValueSort = this.searchResults.find(
              (o) => o._id == val
            ).DisplayLabel;
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      selectedWebsite: "selectedWebsite",
    }),

    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },

    searchResultsWithUpdatedItemText() {
      return this.searchResults.map((item) => {
        let val;
        if (item["DisplayLabels"]) {
          val = item["DisplayLabels"][this.currentLanguage];
        } else if (item["DisplayLabel"]) {
          val = item["DisplayLabel"];
        }
        return { ...item, itemText: val };
      });
    },

    checkGoToLinkedContent() {
      return this.$helpers.checkGoToLinkedContent(
        this.userIsAdministrator,
        this.item.LinkedContent,
        this.item,
        this.selectedWebsite.Pages
      );
    },
    dropdownRule() {
      if (this.item.Required == "true" || this.item.Required == true) {
        return [(v) => !!v || this.$i18n.translate("You must select an item")];
      }

      return [];
    },
  },
  methods: {
    getSharedPagesNotAssigned() {
      return this.selectedWebsite.Pages.filter(
        (p) => p.RepeatingContent === true
      );
    },
    showItemAutocomplete(item) {
      return item.itemText;
    },
    showItem(item) {
      if (item["DisplayLabels"]) {
        return item.DisplayLabels[this.currentLanguage];
      } else if (item["DisplayLabel"]) {
        return item.DisplayLabel;
      }
    },

    getItemDefaultLanguage() {
      return this.$config.contentLanguages[0];
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    searchContent(searchValue) {
      if (this.searchingContent) return;
      if (!this.item.LinkedPage) return;
      this.searchingContent = true;
      this.$request.get(
        "/pagecontentsearch/" +
          this.item.LinkedPage.$oid +
          "?searchValue=" +
          searchValue +
          "&language=" +
          this.currentLanguage +
          "&parentPageId=" +
          this.pageId,
        null,
        (res) => {
          if (res.success) {
            if (this.item.MultipleItems) {
              var concatSearchResults = this.searchResults.concat(res.data);
              for (var i = 0; i < concatSearchResults.length; ++i) {
                for (var j = i + 1; j < concatSearchResults.length; ++j) {
                  if (
                    concatSearchResults[i]._id.$oid ===
                    concatSearchResults[j]._id.$oid
                  )
                    concatSearchResults.splice(j--, 1);
                }
              }
              this.searchResults = concatSearchResults;
            } else {
              this.searchResults = res.data;
            }
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }

          this.searchingContent = false;
        }
      );
    },
    getContent(id) {
      if (Array.isArray(id)) {
        var getContentUrls = [];
        for (var idIndex in id) {
          getContentUrls.push("/content/" + id[idIndex].$oid);
        }
        this.$request.getAll(getContentUrls, null, (res) => {
          this.searchResults = [];
          for (var resIndex in res) {
            if (res[resIndex].success) {
              this.searchResults.push(res[resIndex].data);
            } else {
              const toaster = {
                message: res[resIndex],
                timeout: false,
                type: "ERROR",
                toastClassName: ["toaster-color-error", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-alert-octagon",
              };
              this.$store.dispatch("setToaster", toaster);
            }
          }
        });
      } else {
        this.$request.get("/content/" + id.$oid, null, (res) => {
          if (res.success) {
            this.searchResults = [];
            this.searchResults.push(res.data);
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        });
      }
    },

    remove(item) {
      const index = this.item.LinkedContent.findIndex(
        (obj) => obj.$oid == item._id.$oid
      );
      if (index > -1) {
        this.item.LinkedContent.splice(index, 1);
      }
    },
  },
};
</script>

<style >
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit;
}

.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
</style>